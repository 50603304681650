import "pjax"
import "helpers/symbols"
import "helpers/"
import "company"
import "components/"
import "dashboards/"
import "headcount_plans"
import "importers/"
import "job_application"
import "job_application_votes"
import "job_application_files"
import "job_sources"
import "login"
import "pjax_paginated_table"
import "people_edit"
import "previews/"
import "profile"
import "modals/"
import "uploaders/"
import "ui"
import "settings/"
import "succession_strategies"
import "subforms/"
import "positions"
import "overrides/"
import "wysiwyg"
import "allocations"
import "assets"

import ReactInitializer from "ReactInitializer"

import * as BudgetTable from "v2/modals/position_modal/budget_table"
import * as FilledByInput from "v2/modals/position_modal/filled_by_input"
import * as FundingSources from "v2/modals/position_modal/position_funding_sources"
import * as TitleInput from "v2/modals/position_modal/title_input"
import { InlineOrgUnitForm } from "v2/subforms/inlineOrgUnitForm"

window.InlineOrgUnitForm = InlineOrgUnitForm
window.BudgetTable = BudgetTable
window.FundingSources = FundingSources
window.FilledByInput = FilledByInput
window.TitleInput = TitleInput

// This is an (arbitrary) delay used to help attach event listeners to elements
// once all React components have mounted. Using this is a (hopefully) temporary
// solution and we should work to remove it once we can use server side
// rendering.
window.REACT_MOUNT_DELAY = 350

ReactInitializer.loadDependenciesThenMount()
